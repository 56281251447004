.pageNav {
  position: relative;
  @include mq-pc {
    &:after,
    &:before {
      position: absolute;
      height: 1px;
      width: 100%;
      background-color: clr(border);
      content: "";
      left: 0;
      bottom: 0;
    }
  } //after,before
  &__wrapper {
    @media screen and (max-width: 1440px) {
      margin-bottom: 7.292vw;
    }

    @media screen and (min-width: 1441px) {
      margin-bottom: 10.5rem;
    }

    @include mq-sp {
      margin-bottom: 10.933vw;
    }
    &.__long {
      @include mq-pc {
        margin-bottom: rem(940);
      }
      @include mq-sp {
        margin-bottom: rem(600);
      }
    }
  } //__wrapper

  &__item {
    position: relative;
    display: flex;
    // justify-content: flex-end;
    @include mq-pc {
      &:last-child {
        justify-content: flex-end;
      }
    }
    @include mq-sp {
      width: 50%;
      margin-bottom: rem(200);
      &:nth-child(even) {
        justify-content: flex-end;
      }
    }
    &:after {
      position: absolute;
      height: 1px;
      width: 100%;
      background-color: clr(border);
      content: "";
      left: 0;
      bottom: 0;
    }
  }
  &__item__wrap {
    display: flex;
    justify-content: space-between;
    width: 100%;
    @include mq-sp {
      flex-wrap: wrap;
    }
  }
  &__item__txt {
    display: inline-flex;
    white-space: nowrap;
    @include mq-sp {
      font-size: 3.733vw;
    }
  } //__item__txt
  .-current {
    position: relative;
    &:after,
    &:before {
      content: "";
      background-color: clr(sub);
      display: inline-block;
      height: 5px;
      width: 1px;
      bottom: -1px;
      position: absolute;
      z-index: 1;
    }
    .pageNav__item__txt {
      position: relative;
      font-weight: 500;
      &:after {
        position: absolute;
        height: 1px;
        width: 100%;
        content: "";
        left: 0;
        z-index: 1;
        background-color: clr(sub);
        @include mq-pc {
          bottom: 0;
        }
        @include mq-sp {
          bottom: -1px;
        }
      }
    }
  } //current
  &__inner {
    @include mq-pc {
      max-width: 702px;
    }
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
    &.__wide {
      @include mq-pc {
        max-width: 952px;
        margin-right: unset;
      }
    }
  } //__inner
}
