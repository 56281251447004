@charset "UTF-8";

/* hoverZoom
============================================================= */
%zoomWrap {
  overflow: hidden;
  display: block;
}
%zoom {
  display: block;
  width: 100%;
  height: auto;
  transition: all, 0.5s;
  transform: scale(1.2);
}
.hover-effect {
  &.is-zoom {
    &:hover {
      .zoom {
        @extend %zoom;
      }
    } //hover
    .zoomWrap {
      @extend %zoomWrap;
    }
    .zoom {
      transition: all, 0.5s;
    }
  }
} //hover-effect
