@charset "UTF-8";

/* title
============================================================= */

.pageTitle {
  letter-spacing: 0.2em;
  line-height: 1;
  &.__en {
    font-family: $enfont;
  }
  &.__titleLevel {
    &01 {
      @include mq-pc {
        font-size: 2.6rem;
      }
      @include mq-sp {
        font-size: 5.333vw;
      }
    }
  }
}

.section__title {
  letter-spacing: 0.2em;
  line-height: 1;
  &.__en {
    font-family: $enfont;
  }
  &.__titleLevel {
    &01 {
      @include mq-pc {
        font-size: 2.2rem;
        margin-bottom: 7.5rem;
      }
      @include mq-sp {
        font-size: 5.333vw;
        margin-bottom: (53 / 375 * 100) + vw;
      }
    }
    &02 {
      @include mq-pc {
        font-size: 1.8rem;
        margin-bottom: 4.6rem;
      }
      @include mq-sp {
        font-size: 4.267vw;
        margin-bottom: (28.25 / 375 * 100) + vw;
      }
    }

    &03 {
      @include mq-pc {
        font-size: 2rem;
      }
      @include mq-sp {
        font-size: (13 / 375 * 100) + vw;
      }
    }
    &04 {
      @include mq-pc {
        font-size: 2.2rem;
        margin-bottom: 7.3rem;
      }
      @include mq-sp {
        font-size: (17 / 375 * 100) + vw;
        margin-bottom: (48 / 375 * 100) + vw;
      }
    }
  }
}
