@charset "UTF-8";

/* form
============================================================= */

/** =10-4
   * formAdjust
   * ----------------------------------
   */
//common---start
select {
  -webkit-appearance: none; /* ベンダープレフィックス(Google Chrome、Safari用) */
  -moz-appearance: none; /* ベンダープレフィックス(Firefox用) */
  appearance: none; /* 標準のスタイルを無効にする */
}
::-ms-expand {
  /* select要素のデザインを無効にする（IE用） */
  display: none;
}
/* プレースホルダーの色 */
::placeholder {
  color: clr(gray);
}
input:-webkit-autofill {
  box-shadow: 0 0 0 1000px white inset;
}
button {
  &.selectPost_label {
    color: clr(text) !important;
  } //selectPost_label
}
.fcError {
  color: clr(error);
}
input[type="submit"] {
  color: clr(text) !important;
}
input.txt,
input.-txt,
input[type="email"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="url"],
input[type="submit"],
textarea,
.selectWrap select {
  border-radius: unset;
  border-color: clr(gray);
  // font-family: $basefont;
  font-family: "Hiragino Mincho Pro", serif !important;
  line-height: 2;
  @include mq-pc {
    font-size: 1.3rem;
    padding: 1.3rem 1.2rem;
  }
  @include mq-sp {
    font-size: (13.5 / 375 * 100) + vw;
    // padding: (13 / 375 * 100) + vw;
    padding: 1.5vw 3.4666666667vw;
  }
  &:focus {
    border-color: clr(gray);
  }
}

input[type="submit"],
input[type="button"] {
  font-family: "Hiragino Mincho Pro", serif;
}
//common---end
.-errorTxt {
  @include mq-pc {
    font-size: 1.3rem;
    margin-bottom: 4.5rem;
  }
  @include mq-sp {
    font-size: (13.5 / 375 * 100) + vw;
    // margin-bottom: (23 / 375 * 100) + vw;
    margin-bottom: 4.25vw;
  }
}
.form {
  .confirm_block--input {
    @include mq-pc {
      flex: 1;
    }
  }
  &.-confirmBtn {
    margin-left: auto;
    margin-right: auto;
  }
  .formItemList {
    display: flex;
    align-items: center;
    border-top: 1px solid clr(gray);
    @include mq-pc {
      padding-top: 2.5rem;
      padding-bottom: 2.5rem;
    }
    @include mq-sp {
      flex-wrap: wrap;
      // padding-top: 4.5vw;
      // padding-bottom: 4.5vw;
      // padding-top: (23 / 375 * 100) + vw;
      // padding-bottom: (25 / 375 * 100) + vw;
      padding-top: 6.5vw;
      padding-bottom: 6.5vw;
    }
    &.textarea {
      textarea {
        @include mq-pc {
          height: 22.5rem !important;
        }
      }
      @include mq-pc {
        align-items: flex-start;
      }
      @include mq-sp {
        padding-top: 6vw;
        padding-bottom: 6vw;
      }
    }
  } //formItemList
  .mailform_block--label {
    width: 100%;
    @include mq-pc {
      max-width: 25rem;
    }
    @include mq-sp {
      line-height: 1;
      margin-bottom: (10 / 375 * 100) + vw;
    }
    &-validation {
      position: relative;
      &::after {
        content: "※";
        color: clr(error);
        font-size: 1.3rem;
      }
    } //validation
  } //mailform_block--label
  .mailform_block--input {
    width: 100%;
  }
  .w368_pc {
    .mailform_block--input {
      @include mq-pc {
        max-width: 36.8rem;
        width: 100%;
      }
    }
  } //w368_pc
  &.-confirmBtn,
  &.-formBtn {
    margin-right: auto;
    margin-left: auto;
    box-sizing: border-box;
    width: 100%;
    @include mq-pc {
      max-width: $content-inner;
    }
    @media screen and (min-width: $wide-basis) {
      max-width: $content-inner-wide;
    }
  }
  &.-confirmBtn {
    @include mq-sp {
      padding-right: $padding_rl-sp;
      padding-left: $padding_rl-sp;
    }
  }

  &.-formBtn {
    @include mq-pc {
      margin-top: 6.8rem;
    }
    @include mq-sp {
      margin-top: (43.125 / 375 * 100) + vw;
    }
  }
} //form
.privacy {
  &Box {
    margin-right: auto;
    margin-left: auto;
    box-sizing: border-box;
    width: 100%;
    @include mq-pc {
      margin-top: 4.3rem;
      margin-bottom: 7.5rem;
      max-width: $content-inner;
    }
    @media screen and (min-width: $wide-basis) {
      max-width: $content-inner-wide;
    }
    @include mq-sp {
      margin-top: 9.5vw;
      // margin-top: (53.5 / 375 * 100) + vw;
      margin-bottom: (53 / 375 * 100) + vw;
    }
    .checkboxWrap {
      // @include fadeover;
      cursor: pointer;
      margin-left: 0;
    }
    .icon-checkbox {
      border-radius: unset !important;
      bottom: -0.2em !important;
    } //icon-checkbox
  } //Box
  &Detail {
    background-color: white;
    border: 1px solid #a6a6a6;
    overflow-y: scroll;
    @include mq-pc {
      padding: 2.8rem 0 0 2rem;
      height: 22.5rem;
      margin-top: 5rem;
    }
    @include mq-sp {
      padding: (17.25 / 375 * 100) + vw 0 (1 / 375 * 100) + vw (18 / 375 * 100) +
        vw;
      height: (225 / 375 * 100) + vw;
      margin-top: (28.25 / 375 * 100) + vw;
    }
    &::-webkit-scrollbar {
      @include mq-pc {
        width: 2px;
      }
      @include mq-sp {
        width: (2 / 375 * 100) + vw;
      }
    }
    &::-webkit-scrollbar-thumb {
      background: #a6a6a6;
    }
    li {
      font-family: $basefont;
    }
    li:not(:last-child) {
      @include mq-pc {
        margin-bottom: 1.1rem;
      }
      @include mq-sp {
        margin-bottom: (5.5 / 375 * 100) + vw;
      }
    }
    // スクロールバーデザイン調整
    .mCSB_inside > .mCSB_container {
      @include mq-pc {
        margin-right: 2rem;
      }
      @include mq-sp {
        margin-right: (28 / 375 * 100) + vw;
      }
    }
    .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
      background-color: #a6a6a6;
      border-radius: unset;
      width: 2px;
    }
    .mCSB_scrollTools .mCSB_draggerRail {
      background-color: unset;
    }
    .mCSB_1_dragger_vertical {
      @include mq-pc {
        height: 17px;
        max-height: 165px;
        min-height: 30px;
        top: 0px;
      }
      @include mq-sp {
        height: (30 / 375 * 100) + vw !important;
        max-height: (30 / 375 * 100) + vw !important;
        min-height: (30 / 375 * 100) + vw !important;
      }
    }
  } //Detail
} //privacy

//privacyBoxのチェックボタンがある場合のsubmitボタンの処理
#js_formSubmit {
  &.-eventsNon {
    pointer-events: none;
    input {
      pointer-events: none;
    }
  } //enentsNon
} //js_formSubmit
