//--------------------------------------------------------------
// tsumiki用CSS_adjust
//
//
//
//
//--------------------------------------------------------------
@charset "UTF-8";
.-tsumiki {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  div {
    @include mq-pc {
      margin-top: 8rem;
    }
    @include mq-sp {
      // margin-top: (55 / 375 * 100) + vw;
      margin-top: 12.75vw;
    }
  } //div
  .tsumiki-images {
    &-large {
      width: 100%;
      img {
        width: 100%;
      }
      figcaption {
        @include mq-pc {
          margin-top: 1rem;
        }
        @include mq-sp {
          margin-top: (20 / 375 * 100) + vw;
        }
      } //figcaption
    }
    &-medium {
      @include mq-pc {
        width: 50%;
      }
      @include mq-sp {
        width: 100%;
      }
      // @include mq-pc {
      //   &:nth-child(even) {
      //     padding-right: 2.5rem;
      //   }
      //   &:nth-child(odd) {
      //     padding-left: 2.5rem;
      //   }
      // }
      figcaption {
        @include mq-pc {
          margin-top: 1rem;
        }
        @include mq-sp {
          // margin-top: (20 / 375 * 100) + vw;
          margin-top: 3.5vw;
        }
      } //figcaption
    } //medium
  } //tsumiki-images
} //-tsumiki

body[id="works"] {
  &[data-controller="detail"] {
    .section__works.-tsumiki {
      @include mq-pc {
        margin-left: -2.5rem;
        margin-right: -2.5rem;
      }
      .tsumiki-images-large,
      .tsumiki-text,
      .tsumiki-images-medium {
        @include mq-pc {
          padding-left: 2.5rem;
          padding-right: 2.5rem;
        }
      }
    }
  }
}
body[id="products"] {
  &[data-controller="detail"] {
    .section__works.-tsumiki {
      @include mq-pc {
        margin-left: -2.5rem;
        margin-right: -2.5rem;
      }
      .tsumiki-images-large,
      .tsumiki-text,
      .tsumiki-images-medium {
        @include mq-pc {
          padding-left: 2.5rem;
          padding-right: 2.5rem;
        }
      }
    }
  }
}
