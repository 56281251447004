@charset "UTF-8";

/* sideContents
============================================================= */
@include mq-pc {
  .sideMainWrap {
    display: flex;
  } //sideMainWrap
  .-sticky {
    // position: sticky;
    // top: 0;
  }
}
.sideContents {
  color: clr(txt);
  font-family: $basefont;
  a {
    color: clr(txt);
    @include fadeover;
  }
  &.-con {
    @include mq-pc {
      &:not(:last-child) {
        margin-bottom: 7.5rem;
      }
    }
  }
  &.-wrap {
    @include mq-pc {
      width: ((1200 / 3) / 1440 * 100) + vw;
      // position: absolute;
    }
    @media screen and (min-width: $wide-basis) {
      width: ((1200 / 3) * 0.1) + rem;
    }
    @include mq-sp {
      z-index: 1;
      // position: relative !important;
      background-color: clr(bgColor);
    }
  } //wrap
  &.-list {
    &.-borderBtm {
      @include mq-sp {
        border-bottom: 1px solid clr(txt);
        padding-bottom: (12.15 / 375 * 100) + vw;
        // padding-top: (4.4 / 375 * 100) + vw;
      }
    }
  } //list
  .moreBtn {
    @include mq-pc {
      margin-top: 1.9rem;
    }
  }
  &.-title {
    letter-spacing: 0.1em;
    @include mq-pc {
      font-size: 1.6rem;
      margin-bottom: 1.8rem;
    }
    @include mq-sp {
      font-size: (15.5 / 375 * 100) + vw;
      border-bottom: 1px solid clr(txt);
      // padding-bottom: (7.75 / 375 * 100) + vw;
      padding-bottom: 3vw;
    }
    &.-borderNon {
      border-bottom: none !important;
    }
    &.-hikibaku_label {
      @include mq-pc {
        margin-bottom: 2.2rem;
      }
    } //-hikibaku_label
  } //-title
  &.-item {
    @include mq-pc {
      padding-right: 4rem;
      &:not(:last-child) {
        margin-bottom: 0.2rem;
      }
    }
    @include mq-sp {
      letter-spacing: 0.05em;
      line-height: 1.5;
      padding-left: (5 / 375 * 100) + vw;
      padding-right: (5 / 375 * 100) + vw;
      &:not(:last-child) {
        // margin-bottom: (8.3 / 375 * 100) + vw;
        margin-bottom: 2.8vw;
      }
    }
    @include mq-pc {
      &.accent {
        position: relative;
        display: flex;
        padding-left: 22px;
        &:before {
          content: "";
          width: 15px;
          height: 1px;
          background-color: clr(gray);
          display: inline-block;
          margin-right: 0.8rem;
          position: absolute;
          top: 1.25rem;
          left: 0;
        }
      } //accent
    }
  } //-item
  @include mq-sp {
    &.-spWrap {
      display: flex;
      width: 100%;
      // margin-left: -(16.5 / 375 * 100) + vw;
      // margin-right: -(16.5 / 375 * 100) + vw;
      justify-content: space-between;
      position: relative;
      z-index: 1;
      .sideContents {
        &.-con {
          position: absolute;
          background-color: clr(bgColor);
          width: (146 / 375 * 100) + vw;
          // top: (96 / 375 * 100) + vw;
          font-size: (15.5 / 375 * 100) + vw;
          &:first-child {
            left: 0;
            margin-right: (16.5 / 375 * 100) + vw;
          }
          &:last-child {
            right: 0;
            margin-left: (16.5 / 375 * 100) + vw;
          }
        }
      } //sideContents
      .-title {
        width: 100%;
        text-align: left;
        color: clr(text) !important;
        span {
          color: clr(textgray) !important;
        }
        @include mq-sp {
          padding-left: (5 / 375 * 100) + vw;
          padding-right: (5 / 375 * 100) + vw;
        }
      }
    } //spWrap
    .-arrowDown {
      position: relative;
      &:after {
        margin-top: -1vw;
        position: absolute;
        content: "";
        width: 0;
        height: 0;
        border-left: (3.5 / 375 * 100) + vw solid transparent;
        border-right: (3.5 / 375 * 100) + vw solid transparent;
        border-top: (4.5 / 375 * 100) + vw solid clr(txt);
        top: 50%;
        transform: translateY(-50%);
        right: 1.3333333333vw;
        // border-top: (5 / 375 * 100) + vw solid clr(txt);
      }
    }
    .-borderNon {
      &.-arrowDown {
        &:after {
          border-bottom: (4.5 / 375 * 100) + vw solid clr(txt);
          border-top: unset;
        }
      }
    }
  }

  &.-current {
    .sideContents,
    a {
      color: clr(text);
    }
    .-title {
      @include mq-sp {
        border-bottom: 1px solid clr(txt);
      }
    }
    .-borderBtm {
      @include mq-sp {
        // padding-bottom: (12.15 / 375 * 100) + vw;
        border-bottom: 1px solid clr(txt);
      }
    }
    // @include mq-sp {
    //   .-arrowDown {
    //     &:after {
    //       border-top: (7 / 375 * 100) + vw solid clr(txt);
    //     }
    //   }
    // }
  } //.-current
  button {
    @include fadeover;
    &:hover {
      cursor: pointer;
    }
  }

  @include mq-pc {
    //moreボタン実装
    .-categoryItem {
      opacity: 1;
    }
    .-categoryItem.is-hidden {
      opacity: 0;
      height: 0;
      margin: 0;
    }
    .moreBtn.is-btn-hidden {
      display: none;
    }
  }
  @include mq-sp {
    .moreBtn {
      display: none;
    }
  }
} //sideContents

@include mq-sp {
  _::-webkit-full-page-media,
  _:future,
  :root .sideContents.-spWrap .sideContents.-con {
    top: -(96 / 375 * 100) + vw;
  }
  _::-webkit-full-page-media,
  _:future,
  :root .l-wrapper {
    padding-top: (96 / 375 * 100) + vw;
  }
}
