@charset "UTF-8";
/* ------------------------------------------------------------------
 fadeIn
------------------------------------------------------------------ */
.js-fadeIn {
  opacity: 0;
  visibility: hidden;
  transform: translate(0, 20px);
  transition: opacity 1.5s ease-in-out, visibility 1.5s ease-in-out,
    transform 1.5s ease-in-out;

  &.is-view {
    opacity: 1;
    visibility: visible;
    transform: translate(0, 0);
    &.late1 {
      transition-delay: 0.4s;
    }

    &.late2 {
      transition-delay: 0.8s;
    }

    &.late3 {
      transition-delay: 1.2s;
    }

    @include mq-pc {
      &.pcLate1 {
        transition-delay: 0.5s;
      }

      &.pcLate2 {
        transition-delay: 0.9s;
      }

      &.pcLate3 {
        transition-delay: 1.3s;
      }
    }
  }
}
