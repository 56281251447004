@charset "UTF-8";

/* pagination
============================================================= */

.pager {
  @include mq-pc {
    margin-top: 3.1rem;
  }
  @include mq-sp {
    margin-top: (51.25 / 375 * 100) + vw;
  }
  &List {
    justify-content: center;
    line-height: 1;
    text-align: center;
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
  } //List
  &Item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    position: relative;
    font-family: $enfont;
    @include mq-pc {
      font-size: 1.43rem;
    }
    @include mq-sp {
      font-size: (14.3 / 375 * 100) + vw;
    }
    a {
      @include fadeover;
    }

    &.-current {
      pointer-events: none;
      position: relative;
      font-weight: 600;
    }

    &:not(.prev):not(.next) {
      @include mq-pc {
        margin: 0 1.15rem;
      }

      @include mq-sp {
        margin: 0 (10 / 375 * 100) + vw;
      }
    }
  }
  .pagerList {
    position: relative;
    // @include fadeover;
    .prev_arrow,
    .next_arrow {
      position: absolute;
      cursor: pointer;
      @include fadeover;
      @include mq-pc {
        width: 6px;
        height: 12px;
      }
      @include mq-sp {
        width: (5.5 / 375 * 100) + vw;
        height: (11 / 375 * 100) + vw;
      }
    }
    .prev_arrow {
      left: 0;
    }
    .next_arrow {
      right: 0;
    }
    // &:before,
    // &:after {
    //   background-size: contain;
    //   content: "";
    //   background-repeat: no-repeat;
    //   position: absolute;
    //   cursor: pointer;
    //   @include fadeover;
    //   @include mq-pc {
    //     width: 6px;
    //     height: 12px;
    //   }
    //   @include mq-sp {
    //     width: (5.5 / 375 * 100) + vw;
    //     height: (11 / 375 * 100) + vw;
    //     top: 0.65vw;
    //   }
    // }
    // &.-prev {
    //   &:before {
    //     left: 0;
    //     background-image: url("/assets/img/common/arrow_left.svg");
    //   }
    // }
    // &.-next {
    //   &:after {
    //     right: 0;
    //     background-image: url("/assets/img/common/arrow_right.svg");
    //   }
    // }
  }
}
