@charset "UTF-8";

/* otherLinkBox
============================================================= */
.-otherLinkBox {
  background-color: #f2f2f2;
  @include mq-pc {
    max-width: $mediumContent-inner;
    padding: 5rem 4.5rem;
    margin-bottom: 14.3rem;
    margin-top: 15rem;
  }
  @include mq-sp {
    padding: (53.5 / 375 * 100) + vw $padding_rl-sp;
    margin-bottom: (66.25 / 375 * 100) + vw;
    margin-top: (68 / 375 * 100) + vw;
    margin-left: -$padding_rl-sp;
    margin-right: -$padding_rl-sp;
  }
  @media screen and (min-width: $wide-basis) {
    max-width: $mediumContent-inner-wide;
  }
}

.otherLink {
  &Box {
  } //box
  .moreLink {
    @include mq-pc {
      margin-top: 3rem;
      font-size: 1.3rem;
    }
    @include mq-sp {
      margin-top: 5vw;
      // margin-top: (25 / 375 * 100) + vw;
      font-size: (12.5 / 375 * 100) + vw;
    }
  }
  &.-item {
    @include mq-pc {
      display: flex;
      align-items: center;
      &:not(:last-child) {
        margin-bottom: 5.5rem;
      }
    }
    @include mq-sp {
      &:not(:last-child) {
        margin-bottom: (58.5 / 375 * 100) + vw;
      }
    }
  } //item
  &.-left {
    width: 100%;
    @include mq-pc {
      max-width: 23.667rem;
    }
    @include mq-sp {
      max-width: (325 / 375 * 100) + vw;
      margin-bottom: (28 / 375 * 100) + vw;
    }
  } //img
  &.-right {
    @include mq-pc {
      padding-left: (35.3 / 1440 * 100) + vw;
      flex: 1;
    }
    @media screen and (min-width: $wide-basis) {
      padding-left: 3.53rem;
    }
  }

  &.-txt {
    @include mq-pc {
      margin-top: 2.6rem;
    }
    @include mq-sp {
      margin-top: (18.25 / 375 * 100) + vw;
    }
    &02 {
      @include mq-pc {
        margin-top: 2.1rem;
      }
      @include mq-sp {
        margin-top: (13 / 375 * 100) + vw;
      }
    } //02
  } //txt
  &.-title {
    line-height: 1;
    @include mq-pc {
      font-size: 2rem;
    }
    @include mq-sp {
      font-size: (16 / 375 * 100) + vw;
    }
  } //title
} //otherLink
