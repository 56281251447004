@charset "UTF-8";

/* table
============================================================= */

.table{
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  width: 100%;

  th,
  td{
    border-left: 1px solid #ccc;
    border-top: 1px solid #ccc;

    @include mq-pc{
      padding: rem(10);
    }

    @include mq-sp{
      padding: vw(10);
    }

    &:not(:last-child){
      border-right: 1px solid #ccc;
    }
  }

  th{
    background-color: #f5f5f5;

    &[scope="row"]{
      width: 33.33%;
    }
  }

  &.-center{
    th,
    td{
      text-align: center;
      vertical-align: middle;
    }
  }
}


/* spTableScroll */

.spTableScroll{
  @include mq-sp {
    overflow-x: scroll;

    > table {
      width: 100%;
      white-space: nowrap;
    }
  }
}