@charset "UTF-8";

/* header
============================================================= */
.headerWrap {
  position: relative;
  // background-color: pink;
  z-index: 2;
  @include mq-pc {
    height: (((34 / 1440 * 100) * 2)) + (31.17 / 1440 * 100) + vw;
  }
  @media screen and (min-width: $wide-basis) {
    height: ((3.4) * 2) + 3.17 + rem;
  }
  @include mq-sp {
    height: ((25 / 375 * 100) * 2) + (26 / 375 * 100) + vw;
  }
  @at-root {
    .-spMenuOpen & {
      height: 100vh;
    }
  }
}
#header {
  position: relative;
  z-index: 100;
  // transition: transform 0.6s;
  top: 0;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  background-color: clr(bgColor);
  // @include mq-pc {
  //   height: (130 / 1440 * 100) + vw;
  // }
  // @include mq-sp {
  //   height: (76 / 375 * 100) + vw;
  // }
  &.-headerDefault {
    transition: transform 0.6s;
    background-color: clr(bgColor);
    // background: pink;
    width: 100%;
    @include mq-pc {
      padding-top: 3.6vw;
      padding-bottom: 3.6vw;
    }
    @media screen and (min-width: $wide-basis) {
      padding-top: 4.9rem;
      padding-bottom: 4.98rem;
    }
    @include mq-sp {
      padding-top: (30 / 375 * 100) + vw;
      padding-bottom: (30 / 375 * 100) + vw;
      padding-left: $content_inner_rl-sp;
      padding-right: $content_inner_rl-sp;
    }
  }
  &.-headerFixed {
    animation: nyuru 0.5s;
    transition: transform 0.6s;
    position: fixed;
    background-color: clr(bgColor);
    z-index: 1;
    width: 100%;
    // background-color: yellow !important;
    // @include mq-pc {
    //   top: -(130 / 1440 * 100) + vw;
    // }
    // @include mq-sp {
    //   top: -(76 / 375 * 100) + vw;
    // }
    // top: 0;
    @include mq-pc {
      padding-top: (34 / 1440 * 100) + vw;
      padding-bottom: (34.8 / 1440 * 100) + vw;
    }
    @media screen and (min-width: $wide-basis) {
      padding-top: 3.4rem;
      padding-bottom: 3.48rem;
    }
    @include mq-sp {
      padding-top: (25 / 375 * 100) + vw;
      padding-bottom: (25 / 375 * 100) + vw;
      padding-left: $content_inner_rl-sp;
      padding-right: $content_inner_rl-sp;
    }
  }
  @at-root {
    .-spMenuOpen & {
      position: fixed;
      height: 100%;
      width: 100%;
      background-color: clr(bgColor);
      z-index: 2;
    }
  }
}

.header__inner {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  height: 100%;
  @include mq-pc {
    max-width: $wrapper-inner;
    height: (31.17 / 1440 * 100) + vw;
  }
  @media screen and (min-width: $wide-basis) {
    height: 3.17rem;
  }
  @include mq-sp {
    height: 6.933333vw;
  }
}
.headerLogo_spMenuWrap {
  display: flex;
  align-items: center;
  width: 100%;

  // height: 100%;
}

.header__logo {
  font-size: 0;
  &:hover {
    @include fadeover;
  }

  a {
    display: block;
    width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
  }

  @at-root {
    #header.-scroll &,
    #header.-megaOpen & {
    }

    #header.-scroll & {
      // opacity: 0;
      // pointer-events: none;
    }

    .-spMenuOpen & {
      background-size: auto 100%;
      position: relative;
      z-index: 100;
      pointer-events: all;

      img {
        opacity: 0;
      }
    }
  }
  &.-defaultImg {
    background: url(/assets/img/common/logo.svg) no-repeat left 0;
    background-size: contain;
    @include mq-pc {
      width: $header_logo_w-pc;
      height: (31.17 / 1440 * 100) + vw;
    }
    @media screen and (min-width: $wide-basis) {
      width: 23.6rem;
      height: 3.17rem;
    }
    @include mq-sp {
      height: $header_logo_h-sp;
      width: $header_logo_w-sp;
    }
    &:hover {
      cursor: pointer;
    }
  }
}

.header__nav {
  opacity: 0;
  // scroll-behavior: smooth;
  // overflow-y: scroll;
  pointer-events: none;
  // background-color: clr(bgColor);
  left: 0;
  top: 0;
  width: 100vw;
  z-index: 99;
  transition-duration: $base-duration / 2;
  font-size: vw(13.5);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  // @include mq-pc {
  //   height: 100%;
  // }
  // @include mq-sp {
  //   height: 100%;
  // }
  @at-root {
    .-spMenuOpen & {
      opacity: 1;
      pointer-events: inherit;
      background-color: clr(bgColor);
      position: fixed;
      height: 100%;
    }
  }
}
.gnavWrap {
  @include mq-sp {
    // padding-top: 8.133vw;
    max-width: 44vw;
    margin-left: auto;
    margin-right: auto;
  }
}

.header__navInner {
  @at-root {
    .-spMenuOpen & {
      width: 100%;
      white-space: nowrap;
      @include mq-pc {
        max-width: 16.9rem;
        margin: auto;
      }
      @include mq-sp {
        // margin-top: auto;
      }
      &::before {
        // background-color: clr(bgColor);
        content: "";
        width: 100%;
        height: vw(90);
        position: fixed;
        left: 0;
        top: 0;
        z-index: 1;
      }

      > * {
        position: relative;
        z-index: 0;
      }
    }
  }
}
.-spMenuOpen {
  .header__navInner:before {
    display: none;
  }
}
/* spMenuBtn */

.spMenuBtn {
  // position: absolute;
  // top: 50%;
  // transform: translateY(-50%);
  display: flex !important;
  right: 0;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  z-index: 10000000;
  margin-left: 3.9rem;
  @include mq-pc {
    width: 1.944vw;
    height: 0.556vw;
    // margin-top: -0.25rem;

    // margin-top: 6px;
  }
  @media screen and (min-width: $wide-basis) {
    width: 2.8rem;
    height: 0.8rem;
  }

  @include mq-sp {
    margin-left: auto;
    width: 7.467vw;
    height: 2.133vw;
  }
  &::before,
  &::after {
    display: none !important;
  }
}

.spMenuBtn__icon {
  display: inline-flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  span {
    background-color: #000;
    height: 1px;
    width: 100%;
    display: inline-block;
    transition: all 0.25s;
    position: absolute;
    left: 0;
    &.first {
      top: 0;
    }
    &.last {
      bottom: 0;
    }
  }
}
.-spMenuOpen {
  .spMenuBtn {
    @include mq-sp {
      margin-left: auto;
    }
  }
  .spMenuBtn__icon {
    span {
      background-color: clr(text);
      bottom: 0;
      // top: auto;
      position: absolute;
      top: 3.75px;
      &.first {
        transform: rotate(-30deg);
      }
      &.last {
        transform: rotate(30deg);
      }
    }
  }
} //-spMenuOpen spMenuBtn__icon
.header__navInner {
  opacity: 0;
  // transition-duration: $base-duration / 12;
}
.-isFadeIn {
  opacity: 1;
}
@include mq-pc {
  @keyframes nyuru {
    0% {
      margin-top: -(130 / 1440 * 100) + vw;
    }
    100% {
      margin-top: 0;
    }
  }
}
@include mq-sp {
  @keyframes nyuru {
    0% {
      margin-top: -(76 / 375 * 100) + vw;
    }
    100% {
      margin-top: 0;
    }
  }
}
