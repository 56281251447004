@charset "UTF-8";

/* arrow
============================================================= */

%linkArrowBase {
  background-color: clr(sub);
  width: 10px;
  height: 10px;
  position: relative;
  display: inline-block;
  &:after {
    background-color: white;
    width: 5px;
    height: 5px;
    position: absolute;
    transform: translate(-50%, -50%);
    content: "";
  }
}
%linkArrowRight {
  clip-path: polygon(0 0, 0% 100%, 100% 50%);
  &:after {
    clip-path: polygon(0 0, 0% 100%, 100% 50%);
    left: 40%;
    top: 50%;
  }
}
%linkArrowLeft {
  clip-path: polygon(0 0, 0% 100%, 100% 50%);
  transform: rotate(-180deg);

  &:after {
    clip-path: polygon(0 0, 0% 100%, 100% 50%);
    left: 40%;
    top: 50%;
  }
}
%linkArrowBottom {
  clip-path: polygon(0 0, 100% 0%, 50% 100%);
  &:after {
    clip-path: polygon(0 0, 100% 0%, 50% 100%);
    left: 50%;
    top: 40%;
  }
}
.linkArrow {
  @extend %linkArrowBase;
  &.__Right {
    @extend %linkArrowRight;
  }
  &.__Left {
    @extend %linkArrowLeft;
  }
  &.__Bottom {
    @extend %linkArrowBottom;
  }
}
