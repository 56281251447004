@charset "UTF-8";

/* form
============================================================= */

/** =10-4
   * form
   * ----------------------------------
   */

// link {
//   display: inline-block;
//   font: inherit;
//   color: #fff;
//   padding: 1.1em 1.5em 1em;
//   border: none;
//   // border-radius: .2em;
//   box-sizing: border-box;
//   background-color: clr(main);
//   font-weight: bold;
//   text-decoration: none;
//   line-height: 1.4;
//   outline: none;
//   cursor: pointer;
//   transition: all 0.3s ease;
//   appearance: none;

//   // color
//   &.sub {
//     background: clr(sub);
//     color: clr(text);
//   }
//   &.whiteMain {
//     background: #fff;
//     color: clr(main);
//     border: 1px solid clr(main);
//     &:hover {
//       background-color: clr(main);
//       color: #fff;
//     }
//     @include mq-sp {
//       padding: 0.6em 0.7em 0.5em;
//     }
//   }
//   &.whiteBlack {
//     background: #fff;
//     color: clr(gray)-dark;
//     border: 1px solid clr(gray)-dark;
//     &:hover {
//       background-color: clr(gray)-dark;
//       color: #fff;
//     }
//     @include mq-sp {
//       padding: 0.6em 0.7em 0.5em;
//     }
//   }
//   &.white {
//     background: #fff;
//     color: clr(text);
//   }
//   &.gray {
//     background: clr(gray);
//     color: clr(text);
//   }
//   &.dark {
//     background: clr(gray)-dark;
//     color: #fff;
//   }
//   &.ghost {
//     border: 1px solid clr(text);
//     background: transparent;
//     color: clr(text);
//   }
//   &.disabled {
//     background: clr(gray);
//     color: clr(border);
//     cursor: default;
//     pointer-events: none;
//   }
//   &.paddingWide {
//     padding: 1.1em 3em 1em;
//   }

//   // size
//   &.large {
//     padding: 1.2em 1.75em 1.1em;
//     font-size: 1.8rem;
//   }
//   &.largeX {
//     padding: 1.2em 1.75em 1.1em;
//     font-size: 2rem;
//   }
//   &.largeXX {
//     padding: 1.2em 1.75em 1.1em;
//     @include mq-sp {
//       font-size: 2rem;
//     }
//     @include mq-sp {
//       font-size: 2.4rem;
//     }
//   }
//   &.small {
//     padding: 0.5em 1em;
//     font-size: 1.4rem;
//   }
//   &.smallX {
//     padding: 0.5em 1em;
//     font-size: 1.2rem;
//   }
//   &.smallXX {
//     padding: 0.5em 1em;
//     font-size: 1rem;
//   }

//   &.w100 {
//     width: 100%;
//     box-sizing: border-box;
//   }

//   // corner
//   &.solid {
//     border-radius: 0;
//   }
//   &.radius {
//     border-radius: 0.25em;
//   }
//   &.round {
//     padding-right: 2em;
//     padding-left: 2em;
//     border-radius: 9999px;
//   }

//   // has arrow
//   &.hasArrowRight {
//     padding-right: 1em;
//     // text-align: left;
//     &.large {
//       padding-right: 1.25em;
//     }
//     &.small,
//     &.smallX,
//     &.smallXX {
//       padding-right: 0.75em;
//     }
//     [class^="icon-"] {
//       position: relative;
//       top: -0.05em;
//       margin-left: 1em;
//     }
//   }
//   &.hasArrowLeft {
//     padding-left: 1em;
//     // text-align: right;
//     &.large {
//       padding-left: 1.25em;
//     }
//     &.small,
//     &.smallX,
//     &.smallXX {
//       padding-left: 0.75em;
//     }
//     [class^="icon-"] {
//       position: relative;
//       top: -0.05em;
//       margin-right: 1em;
//     }
//   }
// }
.no-touch {
  .btn [class^="icon-"] {
    svg,
    path {
      transition: all 0.3s ease;
    }
  }
  // .btn:hover {
  //   &.ghost {
  //     background-color: clr(text);
  //     color: #fff;
  //     [class^="icon-"] svg path {
  //       &.line {
  //         stroke: #fff;
  //       }
  //       &.surface {
  //         fill: #fff;
  //       }
  //     }
  //   }
  //   &.disabled {
  //     background-color: clr(gray)-pale;
  //     color: clr(text);
  //   }
  // }
}
input,
button,
textarea,
select {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
}
input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"],
input[type="url"],
input[type="search"],
input[type="submit"],
input[type="reset"],
input[type="button"],
input.txt,
textarea,
button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"],
input[type="url"],
input[type="search"],
input.txt,
textarea {
  width: 100%;
  padding: 0.85em 1.25em 0.65em;
  border: 1px solid clr(border);
  border-radius: 0.25em;
  box-sizing: border-box;
  background-color: #fff;
  transition: border-color 0.2s ease;
  &:focus {
    border-color: clr(border);
  }
  @include mq-sp {
    font-size: 16px;
  }
}
textarea {
  height: 8em;
  vertical-align: bottom;
}

/* -webkit-input-placeholder */
::-webkit-input-placeholder {
  opacity: 1;
  color: clr(text);
} // Chrome
::-moz-placeholder {
  opacity: 1;
  color: clr(text);
} // FireFox
:-ms-input-placeholder {
  opacity: 1;
  color: clr(text);
} // IE

/* select */
select optgroup {
  color: clr(main);
  option {
    color: clr(text);
  }
}
.selectWrap {
  position: relative;
  &::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 1em;
    margin-top: -3px;
    border-top: 7px solid clr(text);
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    content: "";
  }
  select {
    width: 100%;
    padding: 0.85em 2em 0.65em 1.25em;
    border: 1px solid clr(border);
    border-radius: 0.25em;
    box-sizing: border-box;
    background-color: #fff;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    transition: border-color 0.2s ease;
    &:focus {
      border-color: clr(border);
    }
    @include mq-sp {
      font-size: 16px;
    }
  }
}
/* checkbox & radio */
.checkboxGroupWrap,
.radioGroupWrap {
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.75em;
  margin-left: -0.75em;
  @include mq-sp {
    font-size: 16px;
  }
}
.checkboxWrap,
.radioWrap {
  position: relative;
  margin: 0.25em 0.75em;
  input[type="checkbox"],
  .checkbox,
  input[type="radio"],
  .radio {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;

    & + .icon-checkbox,
    & + .icon-radio {
      display: inline-block;
      position: relative;
      bottom: -0.25em;
      width: 1em;
      height: 1em;
      margin-right: 0.5em;
      border: 1px solid clr(gray);
      background-color: #fff;
      vertical-align: baseline;

      &::after {
        display: block;
        opacity: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        content: "";
        transition: all 0.2s ease-in;
      }
    }
    & + .icon-checkbox {
      border-radius: 0.15em;
      &::after {
        transform: rotate(-45deg);
        width: 60%;
        height: 30%;
        margin-top: -35%;
        margin-left: -35%;
        border-bottom: 2px solid clr(main);
        border-left: 2px solid clr(main);
      }
    }
    & + .icon-radio {
      border-radius: 50%;
      &::after {
        width: 50%;
        height: 50%;
        margin-top: -25%;
        margin-left: -25%;
        border-radius: 50%;
        background: clr(main);
      }
    }
    &:checked + .icon-checkbox:after,
    &:checked + .icon-radio:after {
      opacity: 1;
    }
    &:focus + .icon-checkbox,
    &:focus + .icon-radio {
      border-color: clr(border);
    }
  }
}
