@charset "UTF-8";

/* footer
============================================================= */

#footer {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  position: relative;

  // @media screen and (min-width: $wide-basis) {
  //   max-width: $mediumContent-inner-wide;
  // }
  @include mq-pc {
    // padding-left: 3.125vw;
    // padding-right: 3.819vw;
    max-width: $mediumContent-inner;
    padding-bottom: 4.75rem;
  }
  @include mq-sp {
    padding-bottom: (23.25 / 375 * 100) + vw;
    padding-right: $padding_rl-sp;
    padding-left: $padding_rl-sp;
  }
  @media screen and (min-width: $wide-basis) {
    max-width: $mediumContent-inner-wide;
  }
}

.footer__inner {
  // @include mq-pc {
  //   max-width: $content-inner;
  //   width: 100%;
  //   margin-left: auto;
  //   margin-right: auto;
  // }
}

.footer__logo {
  font-weight: 500;
  white-space: nowrap;
  @include mq-pc {
    margin-bottom: 11.8rem;
  }
  @include mq-sp {
    margin-bottom: (60 / 375 * 100) + vw;
  }
  a {
    color: clr(link);
    text-decoration: none;
    display: inline-block;
    &:hover {
      @include fadeover;
    }
  }
  img {
    @include mq-pc {
      width: 3.375rem;
    }

    @include mq-sp {
      width: (31.82 / 375 * 100) + vw;
    }
  }

  // + * {
  //   @include mq-sp {
  //     margin-top: vw(80);
  //   }
  // }
}
.footer__contents {
  @include mq-pc {
    padding-top: 17.5rem;
    margin-bottom: 4rem;
  }
  @include mq-sp {
    padding-top: (150 / 375 * 100) + vw;
    margin-bottom: (11.5 / 375 * 100) + vw;
  }
  &Left {
    @include mq-pc {
      font-size: 1.1rem;
      width: 18.75vw;
      padding-right: 2rem;
    }
    @include mq-sp {
      text-align: center;
    }
  }
}
.footer__conpanyName {
  letter-spacing: 0.1em;
  @include mq-pc {
    font-size: 1.4rem;
    margin-bottom: 2.3rem;
  }
  @include mq-sp {
    margin-bottom: (11.5 / 375 * 100) + vw;
  }
}
.footer__address {
  // margin-bottom: 2rem;
}
.footer__addressWrap {
  @include mq-sp {
    font-size: (13.5 / 375 * 100) + vw;
    margin-bottom: (34.5 / 375 * 100) + vw;
  }
}

.footer__nav {
  &Wrap {
    margin-left: auto;
    @include mq-pc {
      display: flex;
      flex-wrap: wrap;
    }
    @include mq-sp {
      display: none !important;
    }
  }
  &List {
    display: flex;
    @include mq-pc {
      font-size: 1.1rem;
    }
  }
  &Title {
    font-weight: 600;
    display: block;
    color: clr(sub);
    font-family: $enfont;
    @media screen and (max-width: 1440px) {
      font-size: 0.972vw;
    }

    @media screen and (min-width: 1441px) {
      font-size: 1.4rem;
    }

    @include mq-pc {
      margin-bottom: 2rem;
    }
  }

  &Item {
    @media screen and (max-width: 1440px) {
      &.-works {
        margin-right: 3.681vw;
      }
      &.-about {
        margin-right: 3.819vw;
      }
      &.-service {
        margin-right: 2.778vw;
      }
      &.-recruit {
        margin-right: 2.778vw;
      }
      &.-news {
        margin-right: 4.931vw;
      }
    }

    @media screen and (min-width: 1441px) {
      &.-works {
        margin-right: 5.3rem;
      }
      &.-about {
        margin-right: 5.5rem;
      }
      &.-service {
        margin-right: 4rem;
      }
      &.-recruit {
        margin-right: 4rem;
      }
      &.-news {
        margin-right: 7.1rem;
      }
    }

    @include mq-pc {
      a {
        text-decoration: none;
        @include fadeover;
      }
    }
  }
  &-child {
    &Item {
      line-height: 1;
      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }
      a {
        color: clr(main);
      }
    }
  }
}

.footer__end {
  justify-content: space-between;
  position: relative;
  @include mq-pc {
    font-size: rem(110);
    // flex-direction: row-reverse;
    display: flex;
  }
  @include mq-sp {
    text-align: center;
  }
  .privacyLink {
    @include mq-sp {
      font-size: 2.933vw;
      margin-top: 5.333vw;
      margin-bottom: 5.333vw;
    }
  }
  .footer__copyright {
    text-align: left;
    line-height: 1;
    @include mq-pc {
      font-size: 1.4rem;
      // margin-top: 4rem;
    }
    @include mq-sp {
      font-size: (13.5 / 375 * 100) + vw;
      // margin-top: (48 / 375 * 100) + vw;
    }
  }
  .footerLogoItem {
    a {
      display: block;
      @include fadeover;
    }
    img {
      width: 18.667vw;
    }
  }
}

.footer__pageTop {
  position: absolute;
  // position: fixed;
  display: inline-block;
  @include mq-pc {
    bottom: 4.75rem;
    // right: rem(300);
    right: 0;
  }
  @include mq-sp {
    // bottom: (30 / 375 * 100) + vw;
    // bottom: 6.2vw;
    bottom: 5.5vw;
    // right: 6.6666666667vw;
    right: 9vw;
    // right: 2.666667vw;
    line-height: 1;
  }
  a {
    // font-family: $enfont;
    text-decoration: none;
    @include fadeover;
    font-weight: 400;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    writing-mode: vertical-rl;
    letter-spacing: 0.2em;
    line-height: 1;
    @include mq-pc {
      // height: 5.75rem;
      font-size: 1.3rem;
    }
    @include mq-sp {
      // height: (57.5 / 375 * 100) + vw;
      font-size: (13/ 375 * 100) + vw;
    }
    &::before {
      content: "";
      background-color: clr(gray);
      display: block;
      margin-left: auto;
      margin-right: auto;
      position: absolute;
      bottom: 7.5rem;
      left: 50%;
      transform: translateX(-50%);
      @include mq-sp {
        bottom: 19vw;
      }
    }
    &:before {
      height: 100%;
      width: 1px;
    }
  }
}
