@charset "UTF-8";

/* button
============================================================= */
.btn {
  display: inline-flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-family: $basefont;
  @include fadeover;
  @include mq-pc {
    max-width: 27rem;
  }
  .-txt {
    line-height: 1;
    width: 100%;
    height: 100%;
    text-align: center;
    letter-spacing: 0.1em;
    @include mq-pc {
      padding: 17px 25px;
    }
    @include mq-sp {
      padding: 5.6vw 0;
      font-size: (13.5 / 375 * 100) + vw;
    }
  }
  &.-contact {
    @include mq-pc {
      // max-width: 20rem;
      max-width: 20.6rem;
    }
    @include mq-sp {
      width: 50.667vw;
    }
    .-txt {
      @include mq-pc {
        padding: 17px 0;
      }
      @include mq-sp {
        padding: 4.267vw 0;
      }
    }
  }
  &.-form {
    @include mq-pc {
      max-width: 27rem;
    }
    @include mq-sp {
      width: 100%;
    }
    .-txt {
      @include mq-pc {
        padding: 23px 0;
      }
      @include mq-sp {
        padding: (20 / 375 * 100) + vw 0;
      }
    }
  }
  &.-white {
    color: clr(text);
    background-color: transparent;
    .-txt {
      border: 1px solid clr(gray);
    }
    &.arrow {
      &:after {
        border-top: 1px solid clr(gray);
        border-left: 1px solid clr(gray);
        position: absolute;
      } //after
    }
    &:hover {
      // opacity: 1 !important;
      // .-txt {
      //   transition: all 0.5s ease;
      //   color: clr(white);
      //   border: 1px solid clr(btnBlack);
      //   background-color: clr(btnBlack);
      // }
    } //hover
  } //white
  &.-black {
    color: clr(white);
    .-txt {
      border: 1px solid clr(btnBlack);
      background-color: clr(btnBlack);
    }
    &.arrow {
      &:after {
        border-top: 1px solid clr(white);
        border-left: 1px solid clr(white);
        position: absolute;
      } //after
    }
    &:hover {
      // color: clr(text);
      // .-txt {
      //   transition: all 0.5s ease;
      //   background-color: clr(white);
      //   color: black;
      //   border: 1px solid clr(gray);
      // }
      // &.arrow {
      //   &:after {
      //     border-top: 1px solid clr(gray);
      //     border-left: 1px solid clr(gray);
      //     position: absolute;
      //   } //after
      // }
    } //hover
    .-txt {
      color: clr(white);
    }
  } //black
  &.arrow {
    position: relative;
    text-decoration: none;
    align-items: center;
    display: flex;
    &:after {
      content: "";
      display: block;
      width: 5px;
      height: 5px;
      position: absolute;
    } //after
    &.-right::after {
      transform: rotate(135deg);
      right: 1rem;
    }
    &.-left::after {
      transform: rotate(-45deg);
      left: 1rem;
    }
  }
  &Wrap {
    display: flex;
    @include mq-pc {
      margin-left: -0.75rem;
      margin-right: -0.75rem;
    }

    &.-double {
      @include mq-sp {
        flex-wrap: wrap;
      }
      .btn {
        &:not(:last-child) {
          @include mq-sp {
            margin-bottom: 3.467vw;
          }
        }
      }
      &.-column-reverseSp {
        @include mq-sp {
          flex-direction: column-reverse;
          .btn {
            margin-bottom: 3.467vw;
            &:first-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
    .btn {
      @include mq-pc {
        margin-left: 0.75rem;
        margin-right: 0.75rem;
      }
    } //btn
    &.-center {
      justify-content: center;
    }
    &.-left {
      justify-content: flex-start;
    }
  } //Wrap
} //btn

#js_formSubmit {
  &.-off {
    color: clr(text);
    background-color: transparent;
    .-txt {
      border: 1px solid clr(gray);
      transition: all 0.3s ease 0s;
      // transition: background-color 1000ms linear;
    }
    &.arrow {
      &:after {
        border-top: 1px solid clr(gray);
        border-left: 1px solid clr(gray);
        position: absolute;
      } //after
    }
  }
  &.-on {
    opacity: 1 !important;
    color: clr(white);
    .-txt {
      color: clr(white);
      border: 1px solid clr(btnBlack);
      background-color: clr(btnBlack);
      transition: all 0.3s ease 0s;
      // transition: background-color 1000ms linear;
    }
    &.arrow {
      &:after {
        border-top: 1px solid clr(white);
        border-left: 1px solid clr(white);
        position: absolute;
      } //after
    }
  }
} //js_formSubmit
