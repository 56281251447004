/** 
 * ========================================
 * list styles
 * ========================================
 */
.listStyleNon {
  list-style: none;
  li {
    list-style: none;
    padding-left: 0;
    &:before {
      display: none;
    }
  }
}
ul {
  counter-reset: cntOl;
  li {
    list-style-type: none;
    font-weight: inherit;
  }
  &.disc {
    li {
      position: relative;
      padding-left: 1.25em;

      &::before {
        display: inline-block;
        position: absolute;
        top: 0.8em;
        left: 0;
        width: 0.5em;
        height: 0.5em;
        margin-top: -0.25em;
        // margin-right: .7em;
        // margin-left: -1.2em;
        border-radius: 50%;
        background-color: clr(gray);
        content: "";
      }
    }
    &ColorMain {
      @extend .disc;
      li::before {
        background-color: clr(main);
      }
    }
  }
}
ol {
  counter-reset: cntOl;
  li {
    position: relative;
    padding-left: 2em;
    list-style-type: none;
    font-weight: inherit;

    &::before {
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      width: 1.5em;
      color: inherit;
      font-family: $basefont;
      font-weight: bold;
      text-align: right;
      white-space: nowrap;
      counter-increment: cntOl;
      content: counter(cntOl) ".";
    }
  }
  &.listNumColorMain {
    li::before {
      color: clr(main);
    }
  }
  &.listNumCircleColorMain {
    li::before {
      top: 0.1em;
      width: 1.75em;
      padding-top: 0.1em;
      border-radius: 50%;
      background-color: clr(main);
      font-size: 0.9em;
      color: clr(main);
      text-align: center;
      line-height: 1.65em;
      content: counter(cntOl);
    }
  }
  &.listParentheses {
    margin: 0;
    li {
      list-style-type: none;
      list-style-position: inside;
      counter-increment: cnt;
      &:before {
        display: marker;
        content: "(" counter(cnt) ") ";
      }
    }
  }
  &.listEn {
    counter-reset: en;
    li {
      counter-increment: en;
      left: -0.75em;
      &::before {
        content: counter(en, upper-alpha);
      }
    }
  }
}
