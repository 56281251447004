@charset "UTF-8";

/* navigation
============================================================= */

/* gNav */

.gnavWrap {
}

.gNav {
  display: flex;

  flex-wrap: wrap;
  flex-direction: column;

  > li {
    letter-spacing: 0.2em;
    position: relative;
    // font-weight: 600;
    line-height: 1;
    order: 1;
    @include mq-pc {
      font-size: 1.8rem;
    }
    @include mq-sp {
      font-size: 4.667vw;
    }
    &:not(:last-child) {
      @include mq-pc {
        margin-bottom: 4.3rem;
      }
      @include mq-sp {
        margin-bottom: 9.5vw;
      }
    }

    > a {
      text-decoration: none;
      position: relative;
      z-index: 1;

      @include mq-hover {
        &:hover {
          &::after {
            width: rem(10);
          }
        }
      }
    }

    &.-active,
    &.-activeParent {
      > a {
        position: relative;

        &::after {
        }
      }

      &:not(.-spChildList) {
        a {
          // pointer-events: none;
        }
      }
    }

    &.-spChildList {
      > a {
        padding-right: vw(41);
        .linkArrow {
          margin-left: 1rem;
          &.__Bottom {
            display: none;
          }
        }
      }
    }

    &.-spChildList.-open {
      > a {
        .linkArrow {
          margin-left: 1rem;
          &.__Right {
            display: none;
          }
          &.__Bottom {
            display: inline-block;
          }
        }
      }
    }

    &.-spChildList.-active,
    &.-spChildList.-activeParent {
      > a {
        &::after {
          transition-duration: 0s;
        }
      }
    }
  }
}

/* gNavChild */

.gNavChild {
  // display: none;
  @include mq-pc {
    margin-top: 2.3rem;
  }
  @include mq-sp {
    // margin-top: (23 / 375 * 100) + vw;
    margin-top: 5vw;
  }
  @at-root {
    .-open & {
      display: block;
    }
  }

  li {
    font-weight: 300;
    letter-spacing: 0.045em;
    position: relative;
    display: flex;
    align-items: center;
    @include fadeover;
    &:hover {
      cursor: pointer;
    }
    &:before {
      content: "";
      height: 1px;
      background-color: #b3b3b3;
      display: inline-block;
      @include mq-pc {
        margin-right: 0.8rem;
        width: 1.5rem;
      }
      @include mq-sp {
        margin-right: 2.133vw;
        width: 4vw;
      }
    }
    @include mq-pc {
      font-size: 1.3rem;
    }
    @include mq-sp {
      font-size: 3.333vw;
    }
    &:not(:last-child) {
      @include mq-pc {
        margin-bottom: 2rem;
      }
      @include mq-sp {
        margin-bottom: 5vw;
      }
    }

    a {
      position: relative;
      text-decoration: none;

      // color: #777;
      position: relative;

      @include mq-hover {
        &:hover {
          &::after {
          }
        }
      }

      &::after {
        @include mq-hover {
          transition-duration: 0.3s;
        }
      }
    }

    &.-active {
      a {
        pointer-events: none;

        &::after {
          @include mq-pc {
          }
        }
      }
    }
  }
}

/* languageNav */

.languageNav {
  display: flex;
  position: relative;
  z-index: 1000000;
  color: #8c8c8c;
  margin-left: auto;
  @include mq-pc {
    // margin-top: 0.5rem;
  }
  @include mq-sp {
    margin-top: -1vw;
    display: none;
  }
  > li {
    font-weight: 300;
    line-height: 1;
    font-family: $enfont;
    position: relative;
    &:not(:last-child) {
      // border-right: 1px solid clr(text);
      &:after {
        display: inline-block;
        content: "";
        transform: rotate(20deg);
        width: 1px;
        height: 100%;
        background-color: #8c8c8c;
        position: absolute;
        right: 0;
      }
    }
    @include mq-pc {
      font-size: 1.32rem;
      padding-left: 1.1rem;
      padding-right: 1.1rem;
    }
    @include mq-sp {
      font-size: 3.667vw;
      padding-left: 2.133vw;
      padding-right: 2.133vw;
      height: (12 / 375 * 100) + vw;
    }

    &.-active,
    &.-activeParent {
      a {
        position: relative;
        pointer-events: none;
        font-weight: normal;
        color: clr(text);
        &::after {
          transition-duration: 0s;
        }
      }
    }

    a {
      position: relative;
      text-decoration: none;
      color: #8c8c8c;
      transition-duration: 0.3s;
      white-space: nowrap;
      @include mq-hover {
        &:hover {
          color: clr(text);
        }
      }

      // &::after {
      //   content: "";
      //   display: block;
      //   width: 0;
      //   height: 1px;
      //   position: absolute;
      //   background-color: #1a1a1a;
      //   bottom: vw(-11);

      //   @include mq-hover {
      //     transition-duration: 0.3s;
      //   }
      // }
    }
  }
}
.-spMenuOpen {
  .languageNav {
    @include mq-sp {
      display: inline-flex;
    }
  }
}
.-spMenuOpenItem {
  @include mq-pc {
    margin-top: 10rem;
  }
  @include mq-sp {
    // margin-top: (89 / 375 * 100) + vw;
    display: flex;
    justify-content: end;
    padding-left: $content_inner_rl-sp;
    padding-right: $content_inner_rl-sp;
    position: fixed !important;
    bottom: (35 / 375 * 100) + vw;
    width: 100%;
    align-items: center;
    left: 0;
  }
  .languageNav {
    margin-right: auto;
    margin-left: unset;
  }
  .sns {
    margin-left: auto;
    display: flex;
    img {
      width: 100%;
      height: 100%;
    }
    a {
      transition-duration: 0.3s;
      &:hover {
        opacity: 0.7;
        transition-duration: 0.3s;
      }
    }
  }
  .-insta {
    width: 100%;
    overflow: hidden;
    @include mq-pc {
      margin-right: 3.5rem;
      max-width: 2.475rem;
      max-height: 2.475rem;
    }
    @include mq-sp {
      margin-right: 7.467vw;
      max-width: 6.072vw;
      max-height: 6.072vw;
    }
  }
  .-fb {
    width: 100%;
    overflow: hidden;
    @include mq-pc {
      max-width: 1.16rem;
      max-height: 2.5rem;
    }
    @include mq-sp {
      max-width: 2.845vw;
      max-height: (23 / 375 * 100) + vw;
    }
  }
} //spMenuOpenItem
