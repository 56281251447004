@charset "UTF-8";

/* swiperAdjust
============================================================= */
$contentsSwiper-wrap-wide: 104.4rem;
$contentsSwiper-wrap-pc: (1044 / 1440 * 100) + vw;
$contentsSwiper-wrap-sp: (353 / 375 * 100) + vw;
$contentsSwiper-inner_rl-wide: 0.8rem;

.commonSwiperWrap {
  position: relative;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  @include mq-pc {
    max-width: (1044 / 1440 * 100) + vw;
  }
  @media screen and (min-width: $wide-basis) {
    max-width: 104.4rem;
  }
  @include mq-sp {
    // max-width: $contentsSwiper-wrap-sp;
  }
  &.-wide {
    @include mq-pc {
      max-width: (1243.5 / 1440 * 100) + vw;
    }
    @media screen and (min-width: $wide-basis) {
      max-width: 124.35rem;
    }
  }
  .commonSwiperInner {
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    @include mq-pc {
      max-width: $content-inner;
    }
    @include mq-sp {
      padding-right: $padding_rl-sp;
      padding-left: $padding_rl-sp;
    }
    @media screen and (min-width: $wide-basis) {
      max-width: $content-inner-wide;
    }
    .commonwiper-pagination_OtherWrap {
      margin-right: auto;
      margin-left: auto;
      width: 100%;
      // background-color: yellow;
      position: absolute;
      @include mq-pc {
        max-width: $content-inner;
      }
      @include mq-sp {
        padding-right: 1vw;
        // padding-right: $padding_rl-sp;
        padding-left: $padding_rl-sp;
      }
      @media screen and (min-width: $wide-basis) {
        max-width: $content-inner-wide;
      }
    }
    &.-medium {
      @include mq-pc {
        max-width: $mediumContent-inner;
      }
      @include mq-sp {
        padding-right: $padding_rl-sp;
        padding-left: $padding_rl-sp;
      }
      @media screen and (min-width: $wide-basis) {
        max-width: $mediumContent-inner-wide;
      }
      .commonwiper-pagination_OtherWrap {
        @include mq-pc {
          max-width: $mediumContent-inner;
        }
        @include mq-sp {
          padding-right: 1vw;
          // padding-right: $padding_rl-sp;
          padding-left: $padding_rl-sp;
        }
        @media screen and (min-width: $wide-basis) {
          max-width: $mediumContent-inner-wide;
        }
      }
    }
  } //commonSwiperInner
  .commonSwiper-container {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    width: 100%;
  }

  //大枠

  // arrow
  .commonSwiper-button {
    width: 7px;
    height: 13px;
    background-size: cover;
    position: absolute;
    top: 50%;
    cursor: pointer;
  }
  .commonSwiper-prev {
    background-image: url("/assets/img/common/arrow_left.svg") !important;
    @include mq-pc {
      left: 0;
    }
    @include mq-sp {
      left: (11 / 375 * 100) + vw;
    }
  }
  .commonSwiper-next {
    background-image: url("/assets/img/common/arrow_right.svg") !important;
    @include mq-pc {
      right: 0;
    }
    @include mq-sp {
      right: (11 / 375 * 100) + vw;
    }
  }

  // pagination-other
  .commonwiper-pagination_OtherWrap {
    position: absolute;
    @include mq-pc {
      // margin-top: 1.5rem;
      margin-top: 1.1rem;
    }
    @include mq-sp {
      margin-top: 2.75vw;
      // margin-top: (13 / 375 * 100) + vw;
      left: 0;
    }
  }
  .commonSlideCaptionTxt {
    @include mq-pc {
      margin-top: -0.8rem;
    }
  }
  .swiper-paginationCommonInner {
    display: flex;
    align-items: center;
    justify-content: right;
    position: relative;
    z-index: 1;
    font-family: $enfont;
  }
  .swiper-paginationCommon {
    line-height: 1;
    // background-color: red;
    margin-left: auto;
    // margin-right: auto;
    align-items: center;
    line-height: 1;
    // z-index: 1;

    @include mq-pc {
      font-size: 1.5rem;
      width: 16rem;
    }
    @include mq-sp {
      font-size: (13.75 / 375 * 100) + vw;
      width: 16vw;
    }
  }
  .hyphen {
    height: 1px;
    display: inline-block;
    background-color: clr(gray);
    @include mq-pc {
      width: 15px;
      margin-left: 10px;
      margin-right: 10px;
    }
    @include mq-sp {
      width: (13 / 375 * 100) + vw;
      margin-left: (5 / 375 * 100) + vw;
      margin-right: (5 / 375 * 100) + vw;
    }
  }

  .commonSlideCaptionTxt {
    // background-color: pink;
    z-index: 0;
    top: -0.7rem;
    @include mq-sp {
      margin-top: -1.6vw;
      // margin-top: (6 / 375 * 100) + vw;
    }
    .swiper-slide {
      background-color: clr(bgColor);
      // background-color: beige;
      line-height: 1;
      @include mq-sp {
        padding-bottom: 1vw;
      }
    }
  }
} //contentsSwiper
