@charset "UTF-8";

/* default-fadeIn
============================================================= */

.js-fade_off {
  opacity: 0;
  -webkit-transition: opacity 3s;
  -moz-transition: opacity 3s;
  -o-transition: opacity 3s;
  -ms-transition: opacity 3s;
  transition: opacity 3s;
}

.js-fade_on {
  opacity: 1;
}
